<template>
    <v-main id="home-page">
        <v-img src="@/assets/header.png" min-height="500">
            <v-overlay absolute >
                    <v-toolbar flat dark color="transparent" class="flat">
                        <v-btn icon v-if="$vuetify.breakpoint.smAndDown" @click="menuToggle = !menuToggle">
                            <v-icon v-if="!menuToggle">mdi-menu</v-icon>
                            <v-icon v-else>mdi-close</v-icon>

                        </v-btn>
                        <img src="@/assets/logo-white.png" />
                        <v-spacer></v-spacer>
                            <v-toolbar-items v-if="!$vuetify.breakpoint.smAndDown">
                                <v-btn x-large text v-for="(item,n) in menu" :key="n">
                                    {{ item.text }}
                                </v-btn>
                            </v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn  text to="/auth?mode=login" class="font-weight-bold" large>
                            تسجيل الدخول
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-expand-transition>

                        <v-list v-if="menuToggle && $vuetify.breakpoint.smAndDown" dense class="absolute fw" style="z-index:99">
                            <v-subheader class="justify-center">القائمة الرئيسية</v-subheader>
                            <template v-for="(item,n) in menu">

                                <v-list-item  :key="n">
                                    <v-list-item-title class="text-center">
                                            {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="n < menu.length-1" :key="n+'d'"></v-divider>
                            </template>
                        </v-list>
                    </v-expand-transition>

                    <v-layout d-flex justify-center align-center class="fh fw">
                        <v-card max-width="960" max-height="300" class="fh fw" color="transparent" flat>
                            <v-card-title class=" justify-center" style="font-size: 4rem">
                               كُن مُتَمَيِّزا
                            </v-card-title>
                            <v-card-text class="text-center">
                                <div class="headline  my-12">
                                    هل تحتفل بحدث قريب؟
                                    <br>
                                    مناسبات هنا لجعل تنظيم الحدث احتفالًا بحد ذاته!
                                </div>
                                <v-btn x-large rounded class="justify-self-center" color="rgba(255,255,255,0.1" to="/auth?mode=register">
                                    أشترك الآن مجاناً
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-layout>
            </v-overlay>
        </v-img>
        <section>
            <v-container>
                <v-row class="my-6">
                    <v-col cols="12" sm="4" >
                        <v-img src="@/assets/accept-invite.png" class="mx-auto" max-width="200"></v-img>
                    </v-col>
                    <v-col cols="12" sm="8" class="d-flex flex-column justify-center ">
                        <div class="headline primary--text mb-8">
                        قَبُول الدَّعْوَة وَتَأْكِيد الْوُصُول
                        </div>
                        <p>
                            أهم جزء في التحضير لحدثك هو معرفة عدد الضيوف الذين سيحتفلون معك بالضبط ، لذلك أنشأنا عملية كاملة ، هي الأولى من نوعها ، تتيح لك بنقرة واحدة (حتى في الساعة 2 ليلاً) للدعوة الضيوف ، احصل على تأكيدات الوصول في الوقت الفعلي ووفر الكثير من النفقات غير الضرورية والمتاعب. مع واجهة متقدمة وسهلة الوصول
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="primary text-center py-4 lighten-">
            <v-img src="@/assets/bg.png">

                <v-container >

                    <div class="headline  white--text">
                        مَا هِي الْمُنَاسَبَة؟
                    </div>
                    <div class=" --text text--lighten-3 my-5">
                        في مناسبات ، تم تصميم الدعوة الرقمية خصيصًا لنوع الحدث الذي تستضيفه.
                    </div>
                    <v-row class="my-3">
                        <v-col cols="12" sm="4" v-for="(type,n) in eventTypes" :key="n">
                            <v-card flat color="transparent" dark >
                                <v-img :src="type.src" class="rounded" >
                                    <v-overlay absolute>
                                        <v-card-text class="d-flex flex-column justify-center align-center fh">
                                            <div class="title my-37">
                                            {{ type.title}}
                                            </div>
                                            <div>
                                            {{ type.text}}
                                                
                                            </div>
                                        </v-card-text>
                                    </v-overlay>
                                </v-img>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-container>
            </v-img>
        </section>
        <section class="py-4 text-center" >
            <v-container>
                 <div class="headline  primary--text">
                    الْمَيْزَات
                </div>
                <div class=" --text text--lighten-3 my-5">
                   وهذا مجرد جزء صغير من مجموعة القدرات التي يمكن إعدادها لحدثك.
                </div>
                 <v-row class="my-3">
                    <v-col cols="12" sm="3" v-for="(feature,n) in features" :key="n">
                        <v-card min-height="200" >
                            <v-card-text class="d-flex flex-column justify-center align-center fh">
                                <v-icon size="60">
                                    {{ feature.icon }}
                                </v-icon>
                                <div class="title my-37">
                                    {{ feature.title }}
                                </div>
                                <div>
                                    {{ feature.text}}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <v-footer color="grey lighten-2 pb-3 pt-6">
            <v-container>

                <v-row>
                    <v-col cols="12" sm="4" >
                        <v-img src="@/assets/logo.png" max-width="200" class="mb-6"></v-img>
                        مناسبات وهي شركة ناشئة تم إعدادها لإنشاء تجربة مختلفة وذكية ومتقدمة في إدارة الأحداث.
    نحن نحرص على أن نكون على قمة التكنولوجيا ولا نتنازل عن أي خطوة في عمليات البرمجة والتصميم.
                    </v-col>
                    <!-- <v-col cols="12" sm="3" class="text-center">
                        <v-subheader>
                            أختصارات سريعة
                        </v-subheader>
                        <v-list dense color="grey lighten-2">
                            <v-list-item dense v-for="(item,n) in menu" :key="n" class="transparent">
                                <v-list-item-content>
                                    {{item.text}}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col> -->
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="4">
                        <v-subheader>
                            طرق الأتصال بنا
                        </v-subheader>
                        <v-list dense color="grey lighten-2">
                            <v-list-item dense v-for="(item,n) in contacts" :key="n" class="transparent" @click="href(item)">
                                <v-list-item-icon>
                                    <v-icon>{{item.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <small>
                                        {{item.label}}
                                    </small>
                                    <v-list-item-title>
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" sm="4" >
                        <v-subheader>
                        العنوان
                        </v-subheader>
                        Alnbi road 97/35
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        <v-divider></v-divider>
        <v-subheader class="justify-center grey lighten-2" style="direction: ltr">
            copyright © <a href="https://2bnmedia.com" target="_blank" class="mx-1 grey--text"> 2bnmedia.com </a> 
        </v-subheader>
   
    </v-main>
</template>
<script>
export default {
    name: 'Home',
    data() {
        return {
            menuToggle: false
        }
    },
    computed: {
        menu () {
            return [
                { text: 'الرئيسية' },
                { text: 'خدماتنا' },
                { text: 'من نحن' },
                { text: 'كيف تعمل؟' },
                { text: 'أتصل بنا' },
            ]
        },
        eventTypes () {
            return [
                {
                    src: require('@/assets/wedding.png'),
                    title: 'حفل زفاف',
                    text: 'هل انت مخطوب؟ مبروك! نحن هنا لمساعدتك في إدارة حفل الزفاف الخاص بك بسهولة.'
                },
                {
                    src: require('@/assets/birthday.png'),
                    title: 'أعياد الميلاد',
                    text: 'نظم حفل عيد الميلاد بسلاسة وأريحية'
                },
                {
                    src: require('@/assets/business-event.png'),
                    title: 'مناسبات عمل',
                    text: 'نظام متقدم وديناميكي لإدارة حدث تجاري من صغير إلى كبير.'
                }
            ]
        },
        features () {
            return [
                {
                    icon: 'mdi-smart-card',
                    title: 'دعوة رقمية',
                    text: 'أرسال الدعوة بالواتساب او من خلال رسالة قصيرة sms .'
                },
                {
                    icon: 'mdi-taxi',
                    title: 'تأكيد الوصول',
                    text: 'بنقرة زر واحد تمكن ضيوفك من تأكيد وصولهم في كل زمان ومكان.'
                },
                {
                    icon: 'mdi-table-chair',
                    title: 'تنظيم الجلوس',
                    text: 'أداة رقمية سريعة لإعداد الجداول وإدارة الضيوف ، بما في ذلك تحديثات الوصول في الوقت الفعلي.'
                },
                 {
                    icon: 'mdi-cash',
                    title: 'توفير النفقات',
                    text: 'لخص بسهولة نفقاتك ودخلك من الحدث بما في ذلك قائمة الهدايا من الضيوف.'
                },
                 {
                    icon: 'mdi-near-me',
                    title: 'تعليمات الوصول',
                    text: 'أرسال تعليمات الوصول الى الضيوف حسب التطبيق المفضل'
                },
                 {
                    icon: 'mdi-android-messages',
                    title: 'إشعارات في الوقت الحقيقي',
                    text: 'تلقي التنبيهات (الدفع) في التطبيق في الوقت الفعلي عندما يؤكد الضيف وصوله أو يقوم بتحديث حالة وصوله.'
                },
                 {
                    icon: 'mdi-contacts',
                    title: 'تحميل من جهات الاتصالات',
                    text: 'باستخدام التطبيق ، يمكنك تحميل الضيوف مباشرة من جهات اتصال هاتفك المحمول.'
                },
                 {
                    icon: 'mdi-headset',
                    title: 'دعم سريعة',
                    text: 'لأي سؤال ومساعدة نحن متاحون لك على WhatsApp أو البريد الإلكتروني أو Facebook.'
                }
            ]
        },
        contacts () {
            return [
                {
                    icon: 'mdi-whatsapp',
                    label: 'الوتساب',
                    text: '+972526922233',
                    href: 'https://wa.me/+972526922233'
                },
                {
                    icon: 'mdi-cellphone',
                    label: 'الهاتف',
                    text: '+972526922233',
                    href: 'tel://+972526922233'


                },
                // {
                //     icon: 'mdi-facebook-messenger',
                //     label: 'تشات فيسبوك',
                //     text: 'https://fb.me-'


                // }
            ]
        }
    },
    methods: {

        href ({href}) {
            const a = document.createElement('a')
            a.setAttribute('href', href)
            a.setAttribute('target', '_blank')
            a.click()
            a.remove()
    
        }
    }
}
</script>
<style lang="scss">
#home-page {
    .v-overlay__content {
        height: 100%;
        width: 100%;
    }
}
</style>